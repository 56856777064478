import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import './Volunteer.css'
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import { isEmpty } from "lodash";

const Volunteer = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiVolunteers {
        nodes {
          emailaddress
          howicanhelp
          VolunteerBgcolor
          para
          phonenumber
          verification
          yourname
          volunteerdynamic {
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
        }
      }
    }
  `)

  var IsridVolunteer = data.allStrapiVolunteers.nodes
  var IsridVolunteerDynamic = IsridVolunteer[0].volunteerdynamic[0]
  const star = '*'

  const initialFormData = Object.freeze({
    name: '',
    emailAddress: '',
    phonenumber: '',
    howicanhelp: '',
   

  })
  const [formData, updateFormData] = React.useState(initialFormData)
  const handleChange = e => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    })
  }

  function volunteerfun(){
    var templateParams = {
      emailtype: "Volunteer",
      name: formData.name,
      emailAddress: formData.emailAddress,
      phonenumber : formData.phonenumber,
      howicanhelp : formData.howicanhelp,
    }
    console.log(templateParams)
    if(isEmpty(templateParams.name)==false && isEmpty(templateParams.emailAddress)==false && isEmpty(templateParams.phonenumber)==false && isEmpty(templateParams.howicanhelp)==false){
      emailjs.send('service_dplwkbj','template_7fmjdje',templateParams,'user_32otrDt64WxDm2h4yXuVH')
            .then(function(result) {
              console.log('SUCCESS!', result.status, result.text);
              Swal.fire({
                icon: 'success',
                title: 'Thank you Nomination!',
                text: 'We will get back to you as soon as possible',
                confirmButtonText:'Okay!',
                confirmButtonColor: '#00c851',
              }).then(response => {
                /* Read more about isConfirmed, isDenied below */
                if (response.isConfirmed) {
                  window.location.pathname="/home"
                } 
              })
            },
            function(error) {
            console.log('FAILED...', error);
            }
          );
    }
    else{
      alert('Please fill the form')
    }
  }
  return (
<div class="card" style={{backgroundColor:IsridVolunteer[0].VolunteerBgcolor}}>
          <h2
            class="VolunteerTitle"
            style={{
              fontFamily: IsridVolunteerDynamic.TitleFontFamily,
              fontSize: IsridVolunteerDynamic.TitleFontSize,
              fontWeight: IsridVolunteerDynamic.TitleFontWeight,
              textAlign: IsridVolunteerDynamic.TitleAlignment,
              color: IsridVolunteerDynamic.TitleFontColor,
            }}
          >
            {IsridVolunteerDynamic.TitleName}
          </h2>
          <br/>
          <p><ReactMarkdown source={IsridVolunteer[0].para} /></p>
          <div>
            {/************************** */}
            <label htmlFor="firstname">
              {' '}
              <b>{IsridVolunteer[0].yourname}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your Name"
              name="name"
              required
              onChange={handleChange}
            />
            <br />
          {/************************** */}
          <label htmlFor="email">
                {' '}
                <b>{IsridVolunteer[0].emailaddress}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                required
                onChange={handleChange}
                minlength="2"
                maxlength="50"
              />
              <br/>
              {/************************** */}
              <label htmlFor="phonenumber">
                {' '}
                <b>{IsridVolunteer[0].phonenumber}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
              type="number"
              class="form-control"
              placeholder="Enter the Phone number"
              name="phonenumber"
              required
              onChange={handleChange}
            />
            <br />
            {/************************** */}
            <label htmlFor="howicanhelp">
                {' '}
                <b>{IsridVolunteer[0].howicanhelp}</b>
                <span className="star">{star}</span>&nbsp;
            </label>
            <br />
            <textarea 
            id="howicanhelp" 
            class="form-control" 
            onChange={handleChange}
            name="howicanhelp" 
            rows="6" cols="100"/>
            <br/>
            <br />
            <p>{IsridVolunteer[0].verification}</p>
            <br/>
            <div className="text-center mt-4">
            <button type="submit" class="btn btn-success volunteerbtn" onClick={volunteerfun}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"35%",}}>Submit</button>
            </div>
          </div>
      </div>
  )
}

export default Volunteer
